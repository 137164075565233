export function getTextBulan(e) {
  const bulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juni",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  return bulan[e];
}

export function getTextTanggal(e) {
  let tgl = e.getDate();
  let bln = e.getMonth();
  let thn = e.getFullYear();
  tgl < 10 ? (tgl = "0" + tgl) : (tgl = tgl.toString());
  return tgl + " " + getTextBulan(bln) + " " + thn;
}

export function dateToSql(e) {
  let tgl = e.getDate();
  let bln = e.getMonth();
  let thn = e.getFullYear();
  return thn + "-" + bln + "-" + tgl;
}
