import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Update } from "../../../Component/api";

export default function RefKodeKegiatanTambah(e) {
  const [data, setData] = React.useState({
    kode: null,
    nama: null,
    helperKode: null,
    helperNama: null,
  });

  const [tipe, setTipe] = React.useState(null);

  React.useEffect(() => {
    e.data.tipe === "urusan" && setTipe("Urusan");
    e.data.tipe === "program" && setTipe("Program");
    e.data.tipe === "kegiatan" && setTipe("Kegiatan");
    e.data.tipe === "subkegiatan" && setTipe("Sub Kegiatan");
  }, []);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (data.nama === null || data.nama === "") {
      setData({ ...data, helperNama: "Data belum lengkap / masih kosong" });
    } else {
      setData({ ...data, helperNama: null });
      tipe === "Urusan" &&
        Update("refurusan", data).then(() => {
          kembali();
        });
      tipe === "Program" &&
        Update("refprogram", data).then(() => {
          kembali();
        });
      tipe === "Kegiatan" &&
        Update("refkegiatan", data).then(() => {
          kembali();
        });
      tipe === "Sub Kegiatan" &&
        Update("refsubkegiatan", data).then(() => {
          kembali();
        });
    }
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Ubah {tipe}</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                {e.data.kode} {e.data.uraian}
              </strong>
            </>
            <>
              <TextField
                fullWidth
                size="small"
                variant="standard"
                onChange={(v) =>
                  setData({
                    ...data,
                    kode: e.data.kode,
                    nama: v.currentTarget.value,
                  })
                }
                label={<>Nama {tipe}</>}
                helperText={data.helperNama}
              ></TextField>
              <Button
                sx={{ maxWidth: "150px" }}
                variant="contained"
                onClick={() => handleProses()}
              >
                Ubah
              </Button>
            </>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
