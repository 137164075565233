import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import TextMask from "../../../Component/TextMask";
import { Cari, Insert } from "../../../Component/api";

export default function RefKodeKegiatanTambah(e) {
  const [valid, setValid] = React.useState(false);
  const [data, setData] = React.useState({
    kode: null,
    nama: null,
    helperKode: null,
    helperNama: null,
  });

  function cari(v) {
    setData({ ...data, helperKode: null });
    setValid(false);

    let tabel = "refbelanja";
    let len = 17;
    let kd = v;

    if (v.length === len) {
      setData({ ...data, helperKode: "Loading" });
      Cari(tabel, "kode", kd).then((res) => {
        setData({ ...data, kode: kd, helperKode: null });
        setValid(true);
        if (res.data.length > 0) {
          setData({ ...data, helperKode: "Kode ini sudah digunakan" });
          setValid(false);
        }
      });
    }
  }

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (data.nama === null || data.nama === "") {
      setData({ ...data, helperNama: "Data belum lengkap / masih kosong" });
    } else {
      setData({ ...data, helperNama: null });
      Insert("refbelanja", data).then(() => {
        kembali();
      });
    }
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Tambah Kode Belanja</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                {e.data.kode} {e.data.uraian}
              </strong>
            </>
            <TextMask
              sx={{ maxWidth: "180px" }}
              name="kode"
              label="Kode"
              variant="standard"
              mask="0.0.00.00.00.0000"
              value={data.kode}
              getValue={(v) => cari(v)}
              helperText={data.helperKode}
            />
            {valid && (
              <>
                <TextField
                  fullWidth
                  size="small"
                  variant="standard"
                  onChange={(v) =>
                    setData({ ...data, nama: v.currentTarget.value })
                  }
                  label={<>Nama Uraian Belanja</>}
                  helperText={data.helperNama}
                ></TextField>
                <Button
                  sx={{ maxWidth: "150px" }}
                  variant="contained"
                  onClick={() => handleProses()}
                >
                  Tambah
                </Button>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
