import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Update } from "../../../Component/api";
import NumberFormat from "react-number-format";

export default function DpaUbah(e) {
  const [errorHelper, setErrorHelper] = React.useState(null);

  const [data, setData] = React.useState({
    id: null,
    nilai: null,
  });
  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (data.nilai === null || data.nilai === "") {
      setErrorHelper("Data masih ada yg kosong");
    } else {
      Update("tdpa", data).then(() => {
        kembali();
      });
    }
    // Delete("tdpa", e.data).then(() => {
    //   kembali();
    // });
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Ubah</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                {e.data.kode} {e.data.uraian}
              </strong>

              <Typography variant="caption">Nilai (Rp.):</Typography>
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                customInput={TextField}
                onValueChange={(v) =>
                  setData({ ...data, id: e.data.id, nilai: v.value })
                }
                //onChange={(val) => console.log(val.currentTarget.value)}
              />
            </>
            <Button
              sx={{ maxWidth: "150px" }}
              variant="contained"
              onClick={() => handleProses()}
            >
              Ubah
            </Button>
            <i>{errorHelper}</i>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
