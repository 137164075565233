import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { api, apiUrl, Cari } from "../../../Component/api";
import Loading from "../../../Component/Loading";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

import { Box, width } from "@mui/system";
import DaftarKegiatanTambah from "./DaftarKegiatanTambah";
import DaftarKegiatanUbah from "./DaftarKegiatanUbah";
import DaftarKegiatanHapus from "./DaftarKegiatanHapus";
import TambahSPJ from "./TambahSPJ";
import UbahSPJ from "./UbahSPJ";
import NumberFormat from "react-number-format";
import HapusSPJ from "./HapusSPJ";
import { Link } from "react-router-dom";

const cols = [
  { align: "left", value: "No", width: "50px" },
  { align: "left", value: "Uraian" },
  { align: "left", value: "PPTK" },
  { align: "left", value: "Nilai (Rp.)" },
  { align: "right", value: "" },
];

export default function DaftarKegiatan() {
  const [openDetailKegiatan, setOpenDetailKegiatan] = React.useState(false);
  const [textCari, setTextCari] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dataTree, setDataTree] = React.useState([]);
  const [dataTemp, setDataTemp] = React.useState(null);
  const [activePage, setActivePage] = React.useState("tabel");
  const [editSPJ, setEditSPJ] = React.useState([]);
  const [activeData, setActiveData] = React.useState([
    { kode: "", tipe: "uraian", uraian: "" },
  ]);

  React.useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    api.get("select/tdaftarkegiatan/").then((res) => {
      let data = res.data;
      console.log(data);
      if (data !== null) {
        setDataTemp(data);
        setDataTree(data);
        setLoading(false);
      } else {
        setDataTemp([]);
        setDataTree([]);
        setLoading(false);
      }
    });
  }

  function handleClick(tombol, data) {
    setActivePage(tombol);
    setActiveData(data);
  }

  function FilterTabel(e) {
    if (e.length > 0) {
      let result = dataTemp.filter(
        (el) => JSON.stringify(el).toLowerCase().indexOf(e) !== -1
      );
      setDataTree(result);
    } else {
      setDataTree(dataTemp);
    }
  }

  return (
    <>
      <Card
        sx={{ background: "#ffffffaa", m: "20px" }}
        hidden={activePage !== "tabel"}
      >
        <CardHeader
          title={
            <Box display="flex">
              <>Daftar Tagihan Pembayaran Kegiatan</>
              <Box flexGrow={1} />
              <Stack direction="row">
                <TextField
                  size="small"
                  label="Cari"
                  onChange={(e) => FilterTabel(e.currentTarget.value)}
                />
                <IconButton aria-label="Cari">
                  <SearchIcon />
                </IconButton>
                <IconButton aria-label="Refresh" onClick={() => LoadData()}>
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {cols.map((col, index) => (
                    <TableCell key={index} width={col.width} align={col.align}>
                      {index === cols.length - 1 ? (
                        <Stack direction="row" justifyContent="end">
                          <IconButton
                            color="success"
                            onClick={() => handleClick("tambah", {})}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        col.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTree.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">
                      {row.uraian}{" "}
                      <IconButton
                        aria-label="Ubah"
                        size="small"
                        color="info"
                        onClick={() => handleClick("ubah", row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.nama_pptk}</TableCell>
                    <TableCell align="left">
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={row.nilai}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent="end">
                        <IconButton
                          aria-label="Detail"
                          size="small"
                          color="success"
                          onClick={() => {
                            setOpenDetailKegiatan(true);
                            setActiveData(row);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Tambah SPJ"
                          size="small"
                          color="warning"
                          onClick={() => handleClick("tambahSpj", row)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                        {row.nilai <= 0 && (
                          <IconButton
                            aria-label="Hapus"
                            size="small"
                            color="error"
                            onClick={() => handleClick("hapus", row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {activePage === "ubahSpj" && (
        <UbahSPJ
          data={activeData}
          editSPJ={editSPJ}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "hapusSpj" && (
        <HapusSPJ
          data={activeData}
          editSPJ={editSPJ}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "tambahSpj" && (
        <TambahSPJ
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "tambah" && (
        <DaftarKegiatanTambah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "ubah" && (
        <DaftarKegiatanUbah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "hapus" && (
        <DaftarKegiatanHapus
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      <Loading open={loading} />
      <Modal
        open={openDetailKegiatan}
        onClose={() => setOpenDetailKegiatan(!openDetailKegiatan)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <React.Fragment>
          <DetailKegiatan
            data={activeData}
            setActivePage={setActivePage}
            setEditSPJ={setEditSPJ}
            open={setOpenDetailKegiatan}
          />
        </React.Fragment>
      </Modal>
    </>
  );
}

export function DetailKegiatan(e) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    LoadData();
  }, []);
  function LoadData() {
    Cari("tspj", "id_kegiatan", e.data.id).then((res) => {
      setData(res.data);
    });
  }
  return (
    <Card sx={{ maxWidth: "85%", overflowY: "auto", maxHeight: "80vh" }}>
      <CardContent>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Detail</TableCell>
              <TableCell>:</TableCell>
              <TableCell>{e.data.uraian}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Urusan</TableCell>
              <TableCell>:</TableCell>
              <TableCell>{e.data.nama_urusan}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>:</TableCell>
              <TableCell>{e.data.nama_program}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kegiatan</TableCell>
              <TableCell>:</TableCell>
              <TableCell>{e.data.nama_kegiatan}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sub Kegiatan</TableCell>
              <TableCell>:</TableCell>
              <TableCell>{e.data.nama_subkegiatan}</TableCell>
            </TableRow>
          </TableBody>
          <TableRow>
            <TableCell>Total Realisasi</TableCell>
            <TableCell>:</TableCell>
            <TableCell>
              Rp.{" "}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
                value={e.data.nilai}
              />
              ,-
            </TableCell>
          </TableRow>
        </Table>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Tanggal</TableCell>
                <TableCell>Uraian</TableCell>
                <TableCell>Nilai</TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      window.open(
                        `${apiUrl}cetak/rinciankegiatanpdf/${e.data.id}`,
                        "_blank"
                      )
                    }
                    startIcon={<PrintIcon />}
                  >
                    Cetak
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.tanggal}</TableCell>
                  <TableCell>
                    {row.akun_belanja} {row.belanja}
                    <br />
                    {row.keterangan}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={row.nilai}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <IconButton>
                        <EditIcon
                          onClick={() => {
                            e.setEditSPJ(row);
                            e.setActivePage("ubahSpj");
                            e.open(false);
                          }}
                        />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon
                          onClick={() => {
                            e.setEditSPJ(row);
                            e.setActivePage("hapusSpj");
                            e.open(false);
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
