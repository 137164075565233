import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";

import Menu from "./Component/Menu";
import Panel from "./Component/Panel";
import { Button } from "@mui/material";
import Login from "./Page/Login";

import RefKodeKegiatan from "./Page/Referensi/KodeKegiatan/RefKodeKegiatan";
import RefKodeBelanja from "./Page/Referensi/KodeBelanja/RefKodeBelanja";
import RefJenisDokumen from "./Page/Referensi/Jenis Dokumen/RefJenisDokumen";
import Dpa from "./Page/Transaksi/Dpa/Dpa";
import DaftarKegiatan from "./Page/Transaksi/DaftarKegiatan/DaftarKegiatan";
import Beranda from "./Page/Beranda";
import cookie from "react-cookies";

export default function App() {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const [activePanel, setActivePanel] = React.useState("user");
  const [activePage, setActivePage] = React.useState("login");

  React.useEffect(() => {
    cookie.load("auth") === "Admin" && setActivePage("beranda");
  }, []);

  const handleMenuOpen = () => {
    isMenuOpen === false ? setMenuOpen(true) : setMenuOpen(false);
  };
  const handlePanelOpen = (e) => {
    setActivePanel(e);
    isPanelOpen === false ? setPanelOpen(true) : setPanelOpen(false);
  };

  return (
    <Box
      sx={{ flexGrow: 1, minHeight: "100vh" }}
      display="flex"
      flexDirection="column"
    >
      <AppBar position="fixed">
        <Toolbar>
          {cookie.load("auth") === "Admin" && (
            <IconButton
              hidden={cookie.load("auth") !== "Admin"}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => handleMenuOpen()}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            alt="logo"
            src="./logo/logo-invert.png"
            style={{ maxWidth: "95px" }}
          />
          <Typography variant="h6" noWrap component="div"></Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton
            size="large"
            color="inherit"
            onClick={() => handlePanelOpen("message")}
          >
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>

          <IconButton
            size="large"
            color="inherit"
            onClick={() => handlePanelOpen("notif")}
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handlePanelOpen("user")}
          >
            <AccountCircle />
          </IconButton> */}
          {cookie.load("auth") === "Admin" && (
            <Button
              variant="filled"
              startIcon={<AccountCircle />}
              onClick={() => {
                cookie.save("auth", null, { path: "/" });
                window.location.reload();
              }}
            >
              Keluar
            </Button>
          )}
          {cookie.load("auth") !== "Admin" && (
            <Button
              variant="filled"
              startIcon={<AccountCircle />}
              onClick={() => setActivePage("login")}
            >
              Masuk
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box margin="64px 10px 10px">
        {activePage === "login" && <Login />}
        {activePage === "beranda" && <Beranda />}
        {activePage === "dpa" && <Dpa />}
        {activePage === "daftarKegiatan" && <DaftarKegiatan />}
        {activePage === "parameterKodeKegiatan" && <RefKodeKegiatan />}
        {activePage === "parameterKodeBelanja" && <RefKodeBelanja />}
        {activePage === "parameterJenisDokumen" && <RefJenisDokumen />}
      </Box>
      <Box flexGrow={1} />
      <Box
        display="flex"
        justifyContent="center"
        sx={{ background: "#ffffff87" }}
        alignItems="center"
      >
        <Box>
          <Box display="flex" p="10px 10px">
            <Box
              sx={{
                background: "url('./100px-simeulue.png') no-repeat center",
                backgroundSize: "contain",
                width: "130px",
              }}
            />

            <Box p="10px 20px" textAlign="left">
              <Typography variant="caption" fontWeight="bold" component="div">
                Dinas Pemberdayaan Perempuan, Perlindungan Anak dan Keluarga
                Berencana Kabupaten Simeulue
              </Typography>
              <Typography variant="caption" component="div">
                <strong> AJUDAN </strong> Aplikasi Pengujian Kelengkapan Dokumen
                Pengajuan Pembayaran
              </Typography>
              <Typography variant="caption" component="div">
                Programmer :{" "}
                <a href="mailto:andjanuardi@gmail.com">Andri Januardi, S.Kom</a>
              </Typography>
              <Typography variant="caption" component="div">
                Versi 1.0.1-dev | Hak Cipta @ 2021
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Menu
        state={isMenuOpen}
        open={setMenuOpen}
        activePage={setActivePage}
      ></Menu>
      <Panel
        activePanel={activePanel}
        state={isPanelOpen}
        open={setPanelOpen}
      ></Panel>
    </Box>
  );
}
