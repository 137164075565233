import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { api } from "../../../Component/api";
import Loading from "../../../Component/Loading";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Box } from "@mui/system";
import DpaTambah from "./DpaTambah";
import DpaUbah from "./DpaUbah";
import DpaHapus from "./DpaHapus";
import NumberFormat from "react-number-format";

const cols = [
  { align: "left", value: "Kode" },
  { align: "left", value: "Uraian" },
  { align: "right", value: "Nilai" },
  { align: "right", value: "" },
];

export default function Dpa() {
  const [loading, setLoading] = React.useState(false);
  const [dataTree, setDataTree] = React.useState([]);
  const [dataTemp, setDataTemp] = React.useState(null);
  const [activePage, setActivePage] = React.useState("tabel");
  const [activeData, setActiveData] = React.useState([
    { kode: "", tipe: "uraian", uraian: "" },
  ]);

  React.useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    setLoading(true);
    api.get("select/tdpa/").then((res) => {
      let data = res.data;
      if (data.length > 0) {
        setDataTemp(data);
        setDataTree(data);
        setLoading(false);
      }
    });
  }

  function handleClick(tombol, data) {
    setActivePage(tombol);
    setActiveData(data);
  }

  return (
    <>
      <Card
        sx={{ background: "#ffffffaa", m: "20px" }}
        hidden={activePage !== "tabel"}
      >
        <CardHeader
          title={
            <Box display="flex">
              <>Dokumen Pelaksanaan Anggaran (DPA)</>
              <Box flexGrow={1} />
              <Stack direction="row">
                <IconButton aria-label="Refresh" onClick={() => LoadData()}>
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {cols.map((col, index) => (
                    <TableCell key={index} width={col.width} align={col.align}>
                      {index === cols.length - 1 ? (
                        <Stack direction="row" justifyContent="end">
                          <IconButton
                            color="success"
                            onClick={() =>
                              handleClick("tambah", {
                                kode: "",
                                tipe: "",
                                uraian: "",
                              })
                            }
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        col.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTree.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      sx={
                        row.tipe === "belanja"
                          ? { fontWeight: "unset" }
                          : { fontWeight: "bold" }
                      }
                    >
                      {row.kode}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={
                        row.tipe === "belanja"
                          ? { fontWeight: "unset" }
                          : { fontWeight: "bold" }
                      }
                    >
                      {row.uraian}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={
                        row.tipe === "belanja"
                          ? { fontWeight: "unset" }
                          : { fontWeight: "bold" }
                      }
                    >
                      <NumberFormat
                        value={row.nilai}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent="right">
                        {row.tipe === "belanja" && (
                          <>
                            <IconButton
                              aria-label="Ubah"
                              size="small"
                              color="info"
                              onClick={() => handleClick("ubah", row)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="Hapus"
                              size="small"
                              color="error"
                              onClick={() => handleClick("hapus", row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {activePage === "tambah" && (
        <DpaTambah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "ubah" && (
        <DpaUbah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "hapus" && (
        <DpaHapus
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      <Loading open={loading} />
    </>
  );
}
