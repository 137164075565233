export default function ThemeColor(type) {
  let ret = null;
  switch (type) {
    case "primary":
      ret = "#B71C1C";
      break;
    case "secondary":
      ret = "#263238";
      break;
    case "error":
      ret = "#d32f2f";
      break;
    case "success":
      ret = "#357a38";
      break;
    default:
      ret = null;
      break;
  }
  return ret;
}
