import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { api, Insert, Update } from "../../../Component/api";

export default function DaftarKegiatanUbah(e) {
  const [dataForm, setDataForm] = React.useState({
    id: e.data.id,
    kegiatan: e.data.uraian,
  });

  const [error, setError] = React.useState(null);

  function btnUbah() {
    if (dataForm.kegiatan === null || dataForm.kegiatan === "") {
      setError("Data masih belum lengkap");
    } else {
      setError(null);
      Update("tkegiatan", dataForm).then((res) => {
        kembali();
      });
    }
  }
  React.useEffect(() => {
    setError(null);
  }, [dataForm]);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Ubah Kegiatan</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <TextField
              fullWidth
              label="Nama Kegiatan"
              multiline={true}
              defaultValue={dataForm.kegiatan}
              onChange={(e) =>
                setDataForm({ ...dataForm, kegiatan: e.currentTarget.value })
              }
            />
          </Stack>
          <Box display="flex" alignItems="center" mt="20px" width="100%">
            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              onClick={() => btnUbah()}
            >
              Ubah
            </Button>
            <Box flexGrow="1" />
            <Box color="red">{error}</Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
