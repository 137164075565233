import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Cari, Insert } from "../../../Component/api";
import Loading from "../../../Component/Loading";
import NumberFormat from "react-number-format";

import SearchIcon from "@mui/icons-material/Search";

//DatePicker
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import idLocale from "date-fns/locale/id";
import { dateToSql } from "../../../Variable/StrDate";

export default function TambahSPJ(e) {
  const [loading, setLoading] = React.useState(false);
  const [realisasi, setRealisasi] = React.useState(0);
  const [openCariAkunBelanja, setOpenCariAkunBelanja] = React.useState(false);
  const [cariAkunBelanja, setCariAkunBelanja] = React.useState([]);
  const [dokumen, setDokumen] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [formData, setFormData] = React.useState({
    tanggal: dateToSql(new Date()),
    idKegiatan: e.data.id,
    akunBelanja: null,
    nilai: null,
    penerima: null,
    pekerjaanPenerima: null,
    keterangan: null,
  });

  React.useEffect(() => {
    setError(null);
  }, [formData]);

  //Form
  const tanggal = React.useRef(new Date());
  const subKegiatan = React.useRef();
  const akunBelanja = React.useRef();
  const nilai = React.useRef();
  const pekerjaanPenerima = React.useRef();
  const penerima = React.useRef();
  const keterangan = React.useRef();
  const ada = React.useRef({});
  const sesuai = React.useRef({});

  React.useEffect(() => {
    setLoading(true);
    Cari("refsubkegiatan", "kode", e.data.subkegiatan).then((res) => {
      if (res.data.length > 0) {
        subKegiatan.current.value = res.data[0].uraian;
        setLoading(false);
      }
    });
  }, []);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    setError(null);
    let valid = true;
    for (let i = 0; i < Object.keys(ada.current).length; i++) {
      if (ada.current[i] !== null) {
        valid === true
          ? ada.current[i].checked
            ? (valid = true)
            : (valid = false)
          : (valid = false);
      }
    }
    for (let i = 0; i < Object.keys(sesuai.current).length; i++) {
      if (sesuai.current[i] !== null) {
        valid === true
          ? sesuai.current[i].checked
            ? (valid = true)
            : (valid = false)
          : (valid = false);
      }
    }

    Object.values(formData).map((row) => {
      if (valid === true) {
        row !== null ? (valid = true) : (valid = false);
      }
    });

    if (formData.pagu - realisasi - formData.nilai <= 0) {
      valid = false;
    }

    if (valid) {
      Insert("tspj", formData).then(() => {
        kembali();
      });
    } else {
      setError("Data masih belum lengkap / Pagu tidak cukup");
    }
  }

  function openModal() {
    setOpenCariAkunBelanja(true);
    Cari("tdpa", "kode_subkegiatan", e.data.subkegiatan).then((res) => {
      if (res.data.length > 0) {
        setCariAkunBelanja(res.data);
      } else {
        setCariAkunBelanja([]);
      }
    });
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Tambah SPJ</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                Tagihan Kegiatan : <br />
                {e.data.uraian}
              </strong>
            </>

            <>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={idLocale}
              >
                <MobileDatePicker
                  label="Tanggal"
                  inputFormat="dd/MM/yyyy"
                  value={formData.tanggal}
                  inputRef={tanggal}
                  onChange={(newValue) =>
                    setFormData({ ...formData, tanggal: newValue })
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                label="Sub Kegiatan"
                inputRef={subKegiatan}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {e.data.subkegiatan}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={
                  <>
                    Akun Belanja (Pagu Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={formData.pagu}
                    />
                    )
                  </>
                }
                inputRef={akunBelanja}
                onClick={() => openModal()}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {formData.kodeAkunBelanja}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => openModal()}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={
                  <>
                    Nilai (Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={formData.nilai}
                    />
                    )
                  </>
                }
                type="number"
                inputRef={nilai}
                onChange={(v) => {
                  setFormData({
                    ...formData,
                    nilai: v.currentTarget.value,
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp.</InputAdornment>
                  ),
                }}
              />
              <Divider />
              <Box>
                Pagu : Rp.
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  value={formData.pagu}
                />
                <br />
                Realisasi : Rp.
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  value={realisasi}
                />
                <br />
                SPJ ini : Rp.
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  value={formData.nilai}
                />
                <br />
                <strong style={{ borderTop: "1px solid" }}>
                  Sisa : Rp.
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={formData.pagu - realisasi - formData.nilai}
                  />
                </strong>
              </Box>
              <Divider />
              <Stack direction="row" gap="20px">
                <TextField
                  fullWidth
                  label="Penerima"
                  inputRef={penerima}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      penerima: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Pekerjaan Penerima"
                  inputRef={pekerjaanPenerima}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      pekerjaanPenerima: e.currentTarget.value,
                    })
                  }
                />
              </Stack>
              <TextField
                fullWidth
                label="Keterangan / Detail SPJ"
                inputRef={keterangan}
                multiline={true}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    keterangan: e.currentTarget.value,
                  })
                }
              />
              Kelengkapan Dokumen
              <Stack direction="row">
                <Box>
                  {dokumen.map((row, index) => (
                    <FormControl
                      key={index}
                      component="fieldset"
                      sx={{
                        border: "1px solid",
                        borderColor: "rgba(0, 0, 0, 0.87)",
                        padding: "10px",
                        margin: "10px",
                      }}
                    >
                      <FormLabel component="legend">
                        {row.nama_dokumen}
                      </FormLabel>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="Ada"
                          control={
                            <Checkbox
                              inputRef={(e) => (ada.current[index] = e)}
                            />
                          }
                          label="Ada"
                          labelPlacement="end"
                          onChange={() => setError(null)}
                        />
                        <FormControlLabel
                          value="Sesuai"
                          control={
                            <Checkbox
                              inputRef={(e) => (sesuai.current[index] = e)}
                            />
                          }
                          label="Sesuai"
                          labelPlacement="end"
                          onChange={() => setError(null)}
                        />
                      </FormGroup>
                    </FormControl>
                  ))}
                </Box>
              </Stack>
            </>

            <Box display="flex" alignItems="center" mt="20px" width="100%">
              <Button
                sx={{ maxWidth: "150px" }}
                variant="contained"
                onClick={() => handleProses()}
              >
                Simpan
              </Button>
              <Box color="red" pl="10px">
                {error}
              </Box>
              <Box flexGrow="1" />
            </Box>
          </Stack>
        </CardContent>
      </Card>

      {/* MODAL CARI AKUN BELANJA */}
      <Modal
        open={openCariAkunBelanja}
        onClose={() => setOpenCariAkunBelanja(!openCariAkunBelanja)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ maxWidth: "85%", overflowY: "auto", maxHeight: "80vh" }}>
          <List>
            <Divider />
            {cariAkunBelanja.map((row, index) => (
              <>
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    onClick={() => {
                      akunBelanja.current.value = row.belanja;

                      setFormData({
                        ...formData,
                        kodeAkunBelanja: row.kode_belanja,
                        akunBelanja: row.belanja,
                        pagu: row.nilai,
                      });
                      setOpenCariAkunBelanja(false);

                      Cari(
                        "realisasi",
                        e.data.subkegiatan,
                        row.kode_belanja
                      ).then((res) => {
                        if (res.data.length > 0) {
                          res.data[0].nilai !== null
                            ? setRealisasi(res.data[0].nilai)
                            : setRealisasi(0);
                        }
                      });
                      Cari("dokumen", "kode_belanja", row.kode_belanja).then(
                        (res) => {
                          if (res.data.length > 0) {
                            setDokumen(res.data);
                          }
                        }
                      );
                    }}
                  >
                    <Box>
                      {row.kode_belanja} {row.belanja}
                    </Box>
                    <Box flexGrow="1" />
                    <Box>
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={row.nilai}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Card>
      </Modal>
      <Loading open={loading} />
    </>
  );
}
