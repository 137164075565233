import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import NotifPanel from "./NotifPanel";
import MessagePanel from "./MessagePanel";
import UserPanel from "./UserPanel";

export default function Panel(e) {
  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={"right"}
          open={e.state}
          onClose={() => e.open(false)}
          onOpen={() => e.open(true)}
        >
          <Box sx={{ width: 335 }} role="presentation">
            {e.activePanel === "notif" && <NotifPanel />}
            {e.activePanel === "message" && <MessagePanel />}
            {e.activePanel === "user" && <UserPanel />}
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
