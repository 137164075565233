import * as React from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import {
  FormHelperText,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
} from "@mui/material";
import { Box } from "@mui/system";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={props.id}
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function TextMask(e) {
  const name = e.name;
  const [values, setValues] = React.useState({
    [name]: null,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    e.getValue(event.target.value);
  };

  return (
    <FormControl variant={e.variant} fullWidth sx={e.sx}>
      <InputLabel htmlFor="formatted-text-mask-input">{e.label}</InputLabel>
      <Input
        value={values[name]}
        onChange={handleChange}
        name={e.name}
        // id="formatted-text-mask-input"
        id={e.mask}
        inputComponent={TextMaskCustom}
        startAdornment={
          <InputAdornment position="start">
            <Box pb="2px" sx={{ font: "inherit" }}>
              {e.startAdornment}
            </Box>
          </InputAdornment>
        }
      />
      <FormHelperText>{e.helperText}</FormHelperText>
    </FormControl>
  );
}
