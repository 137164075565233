import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import { Divider, Typography } from "@mui/material";
import ThemeColor from "../Variable/Colors";

import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";

function Pesan(e) {
  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: ThemeColor(e.baru ? "success" : "error") }}>
            {e.baru ? <MailIcon /> : <DraftsIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={e.pengirim}
          secondary={
            <React.Fragment>
              <Typography variant="caption">{e.waktu}</Typography>
              <br />
              <Typography variant="caption">{e.pesan}</Typography>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function MessagePanel() {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem>
        <Typography fontSize="20px" fontWeight="bold">
          Daftar Pesan
        </Typography>
      </ListItem>
      <Divider />
      <Pesan
        baru={true}
        pengirim="Administrator"
        waktu="20 September 2021"
        pesan="Pesan 1"
      />
      <Pesan
        baru={false}
        pengirim="Administrator"
        waktu="20 September 2021"
        pesan="Pesan 2"
      />
      <ListItem>
        <Typography variant="caption">Tidak ada notifikasi baru</Typography>
      </ListItem>
    </List>
  );
}
