import axios from "axios";
//let url = window.location.hostname;
//let port = "1901";
export const apiUrl = `https://ajudan.simeuluekab.go.id/api/`;

export const api = axios.create({
  baseURL: apiUrl,
});

export const Cari = async (tabel, col, value) =>
  await api.get(`search/${tabel}/${col}/${value}`);

export const Grafik = async () => await api.get(`info/datagrafik`);

export const Insert = async (tabel, data) =>
  await api.post(`insert/${tabel}`, data);

export const Update = async (tabel, data) =>
  await api.post(`update/${tabel}`, data);

export const Delete = async (tabel, data) =>
  await api.post(`delete/${tabel}`, data);
