import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Delete } from "../../../Component/api";

export default function RefKodeKegiatanHapus(e) {
  const [tipe, setTipe] = React.useState(null);

  React.useEffect(() => {
    e.data.tipe === "urusan" && setTipe("Urusan");
    e.data.tipe === "program" && setTipe("Program");
    e.data.tipe === "kegiatan" && setTipe("Kegiatan");
    e.data.tipe === "subkegiatan" && setTipe("Sub Kegiatan");
  }, []);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    tipe === "Urusan" &&
      Delete("refurusan", e.data).then(() => {
        kembali();
      });
    tipe === "Program" &&
      Delete("refprogram", e.data).then(() => {
        kembali();
      });
    tipe === "Kegiatan" &&
      Delete("refkegiatan", e.data).then(() => {
        kembali();
      });
    tipe === "Sub Kegiatan" &&
      Delete("refsubkegiatan", e.data).then(() => {
        kembali();
      });
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Hapus {tipe}</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                Anda yakin ingin menghapus : ({e.data.kode}) {e.data.uraian} ?
              </strong>
              <i>Data tidak dapat dikembalikan jika sudah dihapus</i>
            </>
            <Button
              sx={{ maxWidth: "150px" }}
              variant="contained"
              onClick={() => handleProses()}
            >
              Hapus
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
