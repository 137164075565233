import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import PrintIcon from "@mui/icons-material/Print";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import DescriptionIcon from "@mui/icons-material/Description";
import TuneIcon from "@mui/icons-material/Tune";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import TagIcon from "@mui/icons-material/Tag";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import HistoryIcon from "@mui/icons-material/History";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

export default function Menu(e) {
  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={"left"}
          open={e.state}
          onClose={() => e.open(false)}
          onOpen={() => e.open(true)}
        >
          <Box sx={{ width: 280 }} role="presentation">
            <MenuList activePage={e.activePage} open={e.open} />
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export function MenuList(e) {
  const [open, setOpen] = React.useState([]);

  const closeSubs = () => {
    setOpen({ sub1: false, sub2: false, sub3: false });
  };

  const handleClick = (index) => {
    closeSubs();
    index === 1 && setOpen({ sub1: !open.sub1 });
    index === 2 && setOpen({ sub2: !open.sub2 });
    index === 3 && setOpen({ sub3: !open.sub3 });
  };

  const handleActivePage = (page) => {
    e.activePage(page);
    e.open(false);
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Box display="flex" p="10px 10px">
        <Box
          sx={{
            background: "url('./100px-simeulue.png') no-repeat center",
            backgroundSize: "contain",
            width: "150px",
          }}
        />
        <Typography variant="caption" align="left" component="div" pl="10px">
          Dinas Pemberdayaan Perempuan, Perlindungan Anak dan Keluarga Berencana
          Kabupaten Simeulue
        </Typography>
      </Box>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 0 15px",
        }}
      >
        <img alt="logo" src="./logo/logo.png" style={{ maxWidth: "184px" }} />
      </div>
      <Typography
        variant="subtitle2"
        align="center"
        p="0px 10px 10px"
        component="div"
      >
        Aplikasi Pengujian Kelengkapan Dokumen Pengajuan Pembayaran
      </Typography>

      <Divider />

      <ListItemButton onClick={() => handleActivePage("beranda")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Beranda" />
      </ListItemButton>

      <ListItemButton onClick={() => handleActivePage("dpa")}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="DPA" />
      </ListItemButton>

      <ListItemButton onClick={() => handleActivePage("daftarKegiatan")}>
        <ListItemIcon>
          <DomainVerificationIcon />
        </ListItemIcon>
        <ListItemText primary="Daftar Kegiatan" />
      </ListItemButton>

      {/* <ListItemButton onClick={() => handleActivePage("verifikasi")}>
        <ListItemIcon>
          <DomainVerificationIcon />
        </ListItemIcon>
        <ListItemText primary="Proses Verifikasi" />
      </ListItemButton>

      <ListItemButton onClick={() => handleActivePage("riwayatVerifikasi")}>
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText primary="Riwayat Verifikasi" />
      </ListItemButton> */}

      <Divider />

      {/* <ListItemButton onClick={() => handleClick(1)}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText primary="Laporan" />
        {open.sub1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.sub1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("laporanDaftarSpj")}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Daftar SPJ" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("laporanRealisasi")}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Realisasi" />
          </ListItemButton>
        </List>
      </Collapse> */}

      <ListItemButton onClick={() => handleClick(2)}>
        <ListItemIcon>
          <TuneIcon />
        </ListItemIcon>
        <ListItemText primary="Parameter" />
        {open.sub2 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.sub2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("parameterJenisDokumen")}
          >
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="Jenis Dokumen" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("parameterKodeKegiatan")}
          >
            <ListItemIcon>
              <TagIcon />
            </ListItemIcon>
            <ListItemText primary="Kode Kegiatan" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("parameterKodeBelanja")}
          >
            <ListItemIcon>
              <TagIcon />
            </ListItemIcon>
            <ListItemText primary="Kode Belanja" />
          </ListItemButton>
        </List>
      </Collapse>

      {/* <ListItemButton onClick={() => handleClick(3)}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Pengaturan" />
        {open.sub3 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.sub3} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("pengaturanPengguna")}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Pengguna" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleActivePage("pengaturanDaftarPengguna")}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Daftar Pengguna" />
          </ListItemButton>
        </List>
      </Collapse> */}
    </List>
  );
}
