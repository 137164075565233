import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  Modal,
  List,
  ListItem,
  ListItemButton,
  CardActions,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { api, Insert } from "../../../Component/api";

export default function DaftarKegiatanTambah(e) {
  const [dataForm, setDataForm] = React.useState({
    kegiatan: null,
    subKegiatan: null,
    pptk: null,
    nip_pptk: null,
  });

  const [openModal, setOpenModal] = React.useState({
    open: false,
    active: null,
  });

  const [error, setError] = React.useState(null);

  function btnTambah() {
    if (
      dataForm.kegiatan === null ||
      dataForm.subKegiatan === null ||
      dataForm.pptk === null ||
      dataForm.nip_pptk === null ||
      dataForm.kegiatan === "" ||
      dataForm.subKegiatan === "" ||
      dataForm.pptk === "" ||
      dataForm.nip_pptk === ""
    ) {
      setError("Data masih belum lengkap");
    } else {
      setError(null);
      Insert("tkegiatan", dataForm).then((res) => {
        kembali();
      });
    }
  }
  React.useEffect(() => {
    setError(null);
  }, [dataForm]);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Tambah Kegiatan</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <TextField
              fullWidth
              label="Nama Kegiatan"
              multiline={true}
              onChange={(e) =>
                setDataForm({ ...dataForm, kegiatan: e.currentTarget.value })
              }
            />
            <TextField
              multiline={true}
              fullWidth
              label="Sub Kegiatan"
              onClick={() =>
                setOpenModal({
                  ...openModal,
                  open: true,
                  active: "subkegiatan",
                })
              }
              value={dataForm.subKegiatan}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {dataForm.kodeSubKegiatan}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Pejabat Pelaksana Teknis Kegiatan"
              value={dataForm.pptk}
              onClick={() =>
                setOpenModal({ ...openModal, open: true, active: "pptk" })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box display="flex" alignItems="center" mt="20px" width="100%">
            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              onClick={() => btnTambah()}
            >
              Tambah
            </Button>
            <Box flexGrow="1" />
            <Box color="red">{error}</Box>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={openModal.open}
        onClose={() => setOpenModal({ ...openModal, open: !openModal.open })}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ width: "700px", overflowY: "hidden", maxHeight: "80vh" }}>
          {openModal.active === "subkegiatan" && (
            <CariSubKegiatan
              dataForm={dataForm}
              setDataForm={setDataForm}
              open={setOpenModal}
            />
          )}
          {openModal.active === "pptk" && (
            <CariPptk
              dataForm={dataForm}
              setDataForm={setDataForm}
              open={setOpenModal}
            />
          )}
        </Card>
      </Modal>
    </>
  );
}

export function CariSubKegiatan(e) {
  const [data, setData] = React.useState([]);
  const [dataTemp, setDataTemp] = React.useState([]);

  React.useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    api.get("select/subkegiatandpa").then((res) => {
      setData(res.data);
      setDataTemp(res.data);
    });
  }

  function Cari(e) {
    if (e.length > 0) {
      let result = dataTemp.filter(
        (el) => JSON.stringify(el).toLowerCase().indexOf(e) !== -1
      );
      setData(result);
    } else {
      setData(dataTemp);
    }
  }

  function handleClick(v) {
    e.setDataForm({
      ...e.dataForm,
      kodeSubKegiatan: v.kode_subkegiatan,
      subKegiatan: v.subkegiatan,
    });
    e.open({ open: false, active: null });
  }

  return (
    <>
      <List>
        <ListItem>
          <strong>Pilih Sub Kegiatan</strong>
        </ListItem>
        <Divider />
        <ListItem>
          <TextField
            size="small"
            label="Cari"
            fullWidth
            onChange={(e) => Cari(e.currentTarget.value)}
          />
        </ListItem>
        <Divider />
        {data.map((row, index) => (
          <>
            <ListItem disablePadding key={index}>
              <ListItemButton onClick={() => handleClick(row)}>
                {row.kode_subkegiatan} {row.subkegiatan}
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
}

export function CariPptk(e) {
  const [data, setData] = React.useState([]);
  const [dataTemp, setDataTemp] = React.useState([]);

  React.useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    api.get("select/pptk").then((res) => {
      setData(res.data);
      setDataTemp(res.data);
    });
  }

  function Cari(e) {
    if (e.length > 0) {
      let result = dataTemp.filter(
        (el) => JSON.stringify(el).toLowerCase().indexOf(e) !== -1
      );
      setData(result);
    } else {
      setData(dataTemp);
    }
  }

  function handleClick(v) {
    e.setDataForm({
      ...e.dataForm,
      id_pptk: v.id,
      pptk: v.nama,
      nip_pptk: v.nip,
    });
    e.open({ open: false, active: null });
  }

  return (
    <>
      <List>
        <ListItem>
          <strong>Pilih Pejabat Teknis Kegiatan</strong>
        </ListItem>
        <Divider />
        <ListItem>
          <TextField
            size="small"
            label="Cari"
            fullWidth
            onChange={(e) => Cari(e.currentTarget.value)}
          />
        </ListItem>
        <Divider />
        {data.map((row, index) => (
          <>
            <ListItem disablePadding key={index}>
              <ListItemButton onClick={() => handleClick(row)}>
                {row.nama}
                <br />
                NIP. {row.nip}
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
}
