import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Delete } from "../../../Component/api";

export default function HapusSPJ(e) {
  const [tipe, setTipe] = React.useState(null);

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    //console.log(e.editSPJ);
    Delete("tspj", e.editSPJ).then(() => {
      kembali();
    });
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Hapus {tipe}</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                Anda yakin ingin menghapus : {e.editSPJ.keterangan} ?
              </strong>
              <i>Data tidak dapat dikembalikan jika sudah dihapus</i>
            </>
            <Button
              sx={{ maxWidth: "150px" }}
              variant="contained"
              onClick={() => handleProses()}
            >
              Hapus
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
