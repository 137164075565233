import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Divider, Slide } from "@mui/material";
import md5 from "md5";
import { api } from "../Component/api";
import cookie from "react-cookies";

export default function Login(e) {
  const [formData, setFormData] = React.useState({ uname: null, pass: null });
  const [nextForm, setNextForm] = React.useState(false);
  const containerRef = React.useRef(null);

  const handleNextForm = () => {
    setNextForm(!nextForm);
  };

  const handleLogin = () => {
    api.post("Auth/login", formData).then((res) => {
      res.data === 1 && cookie.save("auth", "Admin", { path: "/" });
      window.location.reload();
    });
  };
  return (
    <Box
      display="flex"
      height="70vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Card sx={{ maxWidth: 345 }}>
          <img
            alt="logo"
            src="./logo/logo.png"
            width="325px"
            style={{ padding: "10px 10px 0px" }}
          />
          <Typography
            gutterBottom
            variant="subtitle2"
            align="center"
            mt="10px"
            p="2px 10px"
            component="div"
          >
            Aplikasi Pengujian Kelengkapan Dokumen Pengajuan Pembayaran
          </Typography>
          <Divider />
          <CardContent>
            <Typography variant="caption" component="div">
              {!nextForm ? "Masukkan Nama Pengguna" : "Masukkan Password"}
            </Typography>
            <Slide
              direction="left"
              in={!nextForm}
              container={containerRef.current}
            >
              <TextField
                sx={{ width: "100%", display: nextForm && "none" }}
                label="Nama Pengguna"
                variant="standard"
                onChange={(e) =>
                  setFormData({ ...formData, uname: e.currentTarget.value })
                }
              />
            </Slide>
            <Slide
              direction="left"
              in={nextForm}
              container={containerRef.current}
            >
              <TextField
                sx={{ width: "100%", display: !nextForm && "none" }}
                label="Password"
                type="password"
                variant="standard"
                onChange={(e) =>
                  setFormData({ ...formData, pass: md5(e.currentTarget.value) })
                }
              />
            </Slide>
          </CardContent>
          <CardActions sx={{ padding: "0px 10px 10px" }}>
            <Box flexGrow="1" />
            <Button size="medium" onClick={() => handleNextForm()}>
              {nextForm ? "Kembali" : "Lanjutkan"}
            </Button>
            {nextForm && (
              <Button
                size="medium"
                variant="contained"
                onClick={() => handleLogin()}
              >
                Masuk
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}
