import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import ThemeColor from "../Variable/Colors";
import { Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BusinessIcon from "@mui/icons-material/Business";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

export default function UserPanel() {
  return (
    <>
      <Box p="10px">
        <Box pt="20px" sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            sx={{ width: 150, height: 150, bgcolor: ThemeColor("primary") }}
          >
            <PersonIcon sx={{ width: 100, height: 100 }} />
          </Avatar>
        </Box>
        <Box mt="20px" textAlign="center">
          <Typography fontSize="20px" fontWeight="bold" lineHeight="16px">
            Andri Januardi, S.Kom
          </Typography>
          <Typography variant="caption">Administrator</Typography>
        </Box>

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: ThemeColor("secondary") }}>
                <AssignmentIndIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="NIP." secondary="19920119 202012 1 006" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: ThemeColor("secondary") }}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Jabatan" secondary="Bendahara Pengeluaran" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: ThemeColor("secondary") }}>
                <BusinessIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Bidang" secondary="Keuangan" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: ThemeColor("secondary") }}>
                <HistoryIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Login terakhir"
              secondary="Senin, 20 September 2021"
            />
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box display="flex" mt="20px" justifyContent="center">
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" endIcon={<LogoutIcon />}>
            Keluar
          </Button>
          <IconButton aria-label="Pengaturan">
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
}
