import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Cari, Update } from "../../../Component/api";

export default function RefKodeKegiatanUbah(e) {
  const [data, setData] = React.useState({
    kode: null,
    nama: null,
    helperKode: null,
    helperNama: null,
  });

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (data.nama === null || data.nama === "") {
      setData({ ...data, helperNama: "Data belum lengkap / masih kosong" });
    } else {
      setData({ ...data, helperNama: null });
      Update("refjenisdokumen", data).then(() => {
        kembali();
      });
    }
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Ubah Rincian Dokumen Kelengkapan</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>{e.data.dokumen}</strong>
            </>

            <>
              <TextField
                fullWidth
                size="small"
                variant="standard"
                onChange={(v) =>
                  setData({
                    ...data,
                    id: e.data.id,
                    nama: v.currentTarget.value,
                  })
                }
                label={<>Nama Dokumen</>}
                helperText={data.helperNama}
              ></TextField>
              <Button
                sx={{ maxWidth: "150px" }}
                variant="contained"
                onClick={() => handleProses()}
              >
                Ubah
              </Button>
            </>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
