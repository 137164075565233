import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { api } from "../../../Component/api";
import Loading from "../../../Component/Loading";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

import { Box, width } from "@mui/system";
import RefKodeBelanjaTambah from "./RefKodeBelanjaTambah";
import RefKodeBelanjaUbah from "./RefKodeBelanjaUbah";
import RefKodeBelanjaHapus from "./RefKodeBelanjaHapus";

const cols = [
  { align: "left", value: "Kode" },
  { align: "left", value: "Uraian" },
  { align: "right", value: "" },
];

export default function RefKodeBelanja() {
  const [textCari, setTextCari] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dataTree, setDataTree] = React.useState([]);
  const [dataTemp, setDataTemp] = React.useState(null);
  const [activePage, setActivePage] = React.useState("tabel");
  const [activeData, setActiveData] = React.useState([
    { kode: "", tipe: "uraian", uraian: "" },
  ]);

  React.useEffect(() => {
    LoadData();
  }, []);
  function Cari(e) {
    setLoading(true);
    api.get(`search/refbelanja/uraian/${e}`).then((res) => {
      let data = res.data;
      if (data.length > 0) {
        setDataTree(data);
        setLoading(false);
      } else {
        setDataTree(dataTemp);
        setLoading(false);
      }
    });
  }

  function LoadData() {
    setLoading(true);
    api.get("select/kodebelanja/").then((res) => {
      let data = res.data;
      if (data.length > 0) {
        setDataTemp(data);
        setDataTree(data);
        setLoading(false);
      }
    });
  }

  function handleClick(tombol, data) {
    setActivePage(tombol);
    setActiveData(data);
  }

  function LoadMore() {
    console.log(dataTree.length);
    setLoading(true);
    api.get(`select/kodebelanja/${dataTree.length}`).then((res) => {
      let data = res.data;
      if (data.length > 0) {
        setDataTemp(dataTemp.concat(data));
        setDataTree(dataTree.concat(data));
        setLoading(false);
      }
    });
  }

  return (
    <>
      <Card
        sx={{ background: "#ffffffaa", m: "20px" }}
        hidden={activePage !== "tabel"}
      >
        <CardHeader
          title={
            <Box display="flex">
              <>Daftar Referensi Kode Belanja</>
              <Box flexGrow={1} />
              <Stack direction="row">
                <TextField
                  size="small"
                  label="Cari"
                  onChange={(e) => setTextCari(e.currentTarget.value)}
                />
                <IconButton aria-label="Cari" onClick={() => Cari(textCari)}>
                  <SearchIcon />
                </IconButton>
                <IconButton aria-label="Refresh" onClick={() => LoadData()}>
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {cols.map((col, index) => (
                    <TableCell key={index} width={col.width} align={col.align}>
                      {index === cols.length - 1 ? (
                        <Stack direction="row" justifyContent="end">
                          <IconButton
                            color="success"
                            onClick={() =>
                              handleClick("tambah", {
                                kode: "",
                                tipe: "",
                                uraian: "",
                              })
                            }
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        col.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTree.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{row.kode}</TableCell>
                    <TableCell align="left">{row.uraian}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" justifyContent="end">
                        <IconButton
                          aria-label="Ubah"
                          size="small"
                          color="info"
                          onClick={() => handleClick("ubah", row)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Hapus"
                          size="small"
                          color="error"
                          onClick={() => handleClick("hapus", row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <Button
                      variant="contained"
                      sx={{ width: "200px" }}
                      onClick={() => LoadMore()}
                    >
                      Muat lebih banyak..
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {activePage === "tambah" && (
        <RefKodeBelanjaTambah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "ubah" && (
        <RefKodeBelanjaUbah
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      {activePage === "hapus" && (
        <RefKodeBelanjaHapus
          data={activeData}
          setActivePage={setActivePage}
          refresh={LoadData}
        />
      )}
      <Loading open={loading} />
    </>
  );
}
