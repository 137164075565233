import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import TextMask from "../../../Component/TextMask";
import { Cari, Insert } from "../../../Component/api";

export default function RefKodeKegiatanTambah(e) {
  const [valid, setValid] = React.useState(false);
  const [data, setData] = React.useState({
    kode: null,
    nama: null,
    helperKode: null,
    helperNama: null,
  });

  function cari(v) {
    setData({ ...data, helperKode: null });
    setValid(false);

    let tabel = "refjenisdokumen";
    let len = 17;
    let kd = v;

    if (v.length === len) {
      setData({ ...data, helperKode: "Loading" });
      Cari(tabel, "kode_belanja", kd).then((res) => {
        if (res.data.length > 0) {
          setData({
            ...data,
            helperKode: "Kode sudah digunakan",
          });
          setValid(false);
        } else {
          Cari("refbelanja", "kode", kd).then((res2) => {
            setData({ ...data, helperKode: "Kode tidak ditemukan" });
            setValid(false);
            if (res2.data.length > 0) {
              setData({
                ...data,
                kode: kd,
                helperKode: res2.data[0].uraian,
              });
              setValid(true);
            }
          });
        }
      });
    }
  }

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (data.nama === null || data.nama === "") {
      setData({ ...data, helperNama: "Data belum lengkap / masih kosong" });
      console.log(data);
    } else {
      setData({ ...data, helperNama: null });
      let tempdata;
      if (e.data.kode !== "") {
        tempdata = { kode: e.data.kode, nama: data.nama };
      } else {
        tempdata = { kode: data.kode, nama: data.nama };
      }
      Insert("refjenisdokumen", tempdata).then(() => {
        kembali();
      });
    }
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Tambah Rincian Dokumen Kelengkapan</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <>
              <strong>
                {e.data.kode} {e.data.uraian}
              </strong>
            </>
            {e.data.kode === "" && (
              <TextMask
                sx={{ maxWidth: "500px" }}
                name="kode"
                label="Kode"
                variant="standard"
                mask="0.0.00.00.00.0000"
                value={data.kode}
                getValue={(v) => cari(v)}
                helperText={data.helperKode}
              />
            )}

            {(valid || e.data.kode) !== "" && (
              <>
                <TextField
                  aria-readonly={true}
                  fullWidth
                  size="small"
                  variant="standard"
                  label={<>Nama Dokumen</>}
                  onChange={(v) =>
                    setData({ ...data, nama: v.currentTarget.value })
                  }
                  helperText={data.helperNama}
                ></TextField>
                <Button
                  sx={{ maxWidth: "150px" }}
                  variant="contained"
                  onClick={() => handleProses()}
                >
                  Tambah
                </Button>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
