import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import { Badge, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ThemeColor from "../Variable/Colors";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -20,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function Notif(e) {
  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: ThemeColor(e.terima ? "success" : "error") }}>
            {e.terima ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <StyledBadge
                badgeContent={e.baru ? "baru" : null}
                color="primary"
              >
                {e.terima ? "Pengajuan diterima" : "Pengajuan ditolak"}
              </StyledBadge>
            </>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption">{e.waktu}</Typography>
              <br />
              <Typography variant="caption">{e.notif}</Typography>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function NotifPanel() {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem>
        <Typography fontSize="20px" fontWeight="bold">
          Daftar Notifikasi
        </Typography>
      </ListItem>
      <Divider />
      <Notif
        terima={false}
        baru={false}
        waktu="20 September 2021"
        notif="SPJ Makan Minum"
      />
      <ListItem>
        <Typography variant="caption">Tidak ada notifikasi baru</Typography>
      </ListItem>
    </List>
  );
}
