import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import TextMask from "../../../Component/TextMask";
import { Cari, Insert } from "../../../Component/api";
import NumberFormat from "react-number-format";

export default function DpaTambah(e) {
  const [errorHelper, setErrorHelper] = React.useState(null);
  const [data, setData] = React.useState({
    subkegiatan: null,
    belanja: null,
    nilai: null,
    helperSubkegiatan: null,
    helperBelanja: null,
  });

  function cari(v, kriteria) {
    if (kriteria === "subkegiatan") {
      setData({ ...data, helperSubkegiatan: null });
      let tabel = "refsubkegiatan";
      let len = 15;
      let kd = v;
      if (v.length === len) {
        Cari(tabel, "kode", kd).then((res) => {
          if (res.data.length > 0) {
            setData({
              ...data,
              subkegiatan: kd,
              helperSubkegiatan: res.data[0].uraian,
            });
          }
        });
      }
    }
    if (kriteria === "belanja") {
      setData({ ...data, helperBelanja: null });
      let tabel = "refbelanja";
      let len = 17;
      let kd = v;
      if (v.length === len) {
        Cari(tabel, "kode", kd).then((res) => {
          if (res.data.length > 0) {
            setData({
              ...data,
              belanja: kd,
              helperBelanja: res.data[0].uraian,
            });
          }
        });
      }
    }
  }

  function kembali() {
    e.refresh();
    e.setActivePage("tabel");
  }

  function handleProses() {
    if (
      data.subkegiatan === null ||
      data.subkegiatan === "" ||
      data.belanja === null ||
      data.belanja === "" ||
      data.nilai === null ||
      data.nilai === ""
    ) {
      setErrorHelper("Data masih ada yg kosong");
    } else {
      Insert("tdpa", data).then(() => {
        kembali();
      });
    }
  }

  return (
    <>
      <Card sx={{ background: "#ffffffaa", m: "20px" }}>
        <CardHeader
          title={
            <>
              <Box display="flex">
                <>Tambah Akun Belanja</>
                <Box flexGrow={1} />
                <IconButton onClick={() => e.setActivePage("tabel")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Stack gap="20px">
            <TextMask
              fullWidth
              name="kodesubkegiatan"
              label="Kode Sub Kegiatan"
              variant="standard"
              mask="0.00.00.0.00.00"
              getValue={(v) => cari(v, "subkegiatan")}
            />
            <TextMask
              fullWidth
              name="kodebelanja"
              label="Kode Belanja"
              variant="standard"
              mask="0.0.00.00.00.0000"
              getValue={(v) => cari(v, "belanja")}
            />
            <Stack gap="10px">
              <Typography variant="caption">Sub Kegiatan : </Typography>
              <strong>{data.helperSubkegiatan}</strong>
              <Divider />
              <Typography variant="caption">Akun Belanja :</Typography>
              <strong>{data.helperBelanja}</strong>
              <Divider />
              <Typography variant="caption">Nilai (Rp.):</Typography>
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                customInput={TextField}
                onValueChange={(e) => setData({ ...data, nilai: e.value })}
                //onChange={(val) => console.log(val.currentTarget.value)}
              />
            </Stack>
            <Button
              sx={{ maxWidth: "150px" }}
              variant="contained"
              onClick={() => handleProses()}
            >
              Tambah
            </Button>
            <i>{errorHelper}</i>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
