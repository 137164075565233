import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
  Paper,
  Button,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import { Box, height } from "@mui/system";
import { Bar } from "react-chartjs-2";
import { Radar } from "react-chartjs-2";
import { api } from "../Component/api";
import NumberFormat from "react-number-format";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

export default function Beranda() {
  const [dataSummary, setDataSummary] = React.useState([]);
  const [dataRealisasi, setDataRealisasi] = React.useState([]);
  const [dataSpj, setDataSpj] = React.useState([]);

  React.useEffect(() => {
    api.get("info/datasummary").then((res) => {
      setDataSummary(res.data);
      console.log(res.data);
    });
    api.get("info/datagrafik").then((res) => {
      setDataRealisasi(res.data.realisasi);
      setDataSpj(res.data.spj);
      console.log(res.data);
    });
  }, []);

  const data = {
    labels: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juni",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    datasets: [
      {
        type: "line",
        label: "Grafik Garis Realisasi",
        data: dataRealisasi,
        backgroundColor: ["rgba(153, 102, 255, 0.2)"],
        borderColor: ["rgba(153, 102, 255, 1)"],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "Grafik Balok Realisasi",
        data: dataRealisasi,
        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "Jumlah SPJ Masuk",
        data: dataSpj,
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juni",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    datasets: [
      {
        //type: "bar",
        label: "Jumlah SPJ Masuk",
        data: dataSpj,
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
      // {
      //   type: "line",
      //   label: "Jumlah SPJ Masuk",
      //   data: dataSpj,
      //   backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      //   borderColor: ["rgba(255, 99, 132, 1)"],
      //   borderWidth: 1,
      // },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <Stack>
        <Grid container spacing={2} p="20px">
          <Grid item xs={12} md={6}>
            <Card sx={{ background: "#ffffffaa", height: "400px" }}>
              <ImgSlider />
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ background: "#ffffffaa", height: "400px" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Grafik SPJ </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Radar
                  data={data2}
                  options={options}
                  style={{ maxHeight: "300px" }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} p="20px">
          <Grid item xs={12} md={6}>
            <Card sx={{ background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Grafik Realisasi </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Bar
                  data={data}
                  options={options}
                  style={{ maxHeight: "60vh" }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ width: "100%", mb: "20px", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Pagu Anggaran </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.pagu}
                    />
                    ,-
                  </strong>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: "100%", mb: "20px", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Sisa Pagu </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.sisapagu}
                    />
                    ,-
                  </strong>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: "100%", mb: "20px", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Total SPJ Masuk </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.spj}
                    />{" "}
                    Dokumen
                  </strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ width: "100%", mb: "20px", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Total Realisasi ({dataSummary.persen}%) </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.realisasi}
                    />
                    ,-
                  </strong>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: "100%", mb: "20px", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>Realisasi Bulan Ini </strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    Rp.{" "}
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.rbulanini}
                    />
                    ,-
                  </strong>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ width: "100%", background: "#ffffffaa" }}>
              <CardHeader
                title={
                  <>
                    <Box display="flex">
                      <strong>SPJ Bulan Ini</strong>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="h5">
                  <strong>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={dataSummary.spjbulanini}
                    />{" "}
                    Dokumen
                  </strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export function ImgSlider() {
  const slides = [
    {
      title: "Selamat Datang",
      description:
        "Selamat Datang di AJUDAN (Aplikasi Pegujian Kelengkapan Dokumen Pengajuan Pembayaran)",
      image: "../slider/1.jpg",
    },
    {
      title: "DP3AKB Kab. Simeulue",
      description:
        "Dinas Pemberdayaan Perempuan, Perlindungan Anak dan Keluarga Berencana Kab. Simeulue",
      image: "../slider/2.jpeg",
    },
    {
      title: "Latsar Golongan III Angkatan I",
      description:
        "Peserta Latsar Golongan III Angkatan I di Blangkejeren Kab. Gayo Lues",
      image: "../slider/3.jpeg",
    },
    {
      title: "Kelompok I Aktualisasi",
      description:
        "Kelompok Aktualisasi Latsar Golongan III Angkatan I di Blangkejeren Kab. Gayo Lues",
      image: "../slider/4.jpeg",
    },
  ];
  return (
    <Slider autoplay="1000">
      {slides.map((slide, index) => (
        <div key={index}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="280"
              image={slide.image}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {slide.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {slide.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </div>
      ))}
    </Slider>
  );
}
